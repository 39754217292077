/* eslint-disable class-methods-use-this */

// Import libraries
import LazyLoading from "./lib/lazy-loading";
import Lottie from "./lib/lottie";

// Import components
import Cta from "./template-parts/components/cta";
import Filters from "./template-parts/components/filters";
import Pagination from "./template-parts/components/pagination";

// Import sections
import Clients from "./template-parts/sections/clients";
import ContactForm from "./template-parts/sections/contact-form";
import CustomersFeedback from "./template-parts/sections/customers-feedback";
import Facts from "./template-parts/sections/facts";
// import Map from "./template-parts/sections/map";
import Products from "./template-parts/sections/products";
import SliderCards from "./template-parts/sections/slider-cards";
import SliderGallery from "./template-parts/sections/slider-gallery";

export default class ModulesLoader {
	constructor(el = document) {
		this.el = el;
	}

	loadModules() {
		// Load libraries
		window.lazyLoading = new LazyLoading();
		window.lottie = new Lottie();

		// Load components
		window.cta = new Cta();
		window.filters = new Filters();
		window.pagination = new Pagination();

		// Load sections
		window.clients = new Clients();
		window.contactForm = new ContactForm();
		window.customersFeedback = new CustomersFeedback();
		window.facts = new Facts();
		// window.map = new Map();
		window.products = new Products();
		window.sliderCards = new SliderCards();
		window.sliderGallery = new SliderGallery();
	}
}
