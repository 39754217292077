import Swiper from "swiper/bundle";

class CustomersFeedback {
	constructor() {
		this.customersFeedbackSections =
			document.getElementsByClassName("customers-feedback");
		if (this.customersFeedbackSections[0]) {
			this.init();
		}
	}

	/* eslint-disable class-methods-use-this, no-unused-vars */
	init() {
		Array.from(this.customersFeedbackSections).forEach((section) =>
			this.initSlider(section)
		);
	}

	initSlider(section) {
		const sliderContainer = section.querySelector(".swiper-container");
		const slider = new Swiper(sliderContainer, {
			// Optional parameters
			direction: "horizontal",
			loop: false,
			slidesPerView: 1,
			spaceBetween: 50,

			// Navigation arrows
			navigation: {
				nextEl: ".js-customers-feedback-swiper-next",
				prevEl: ".js-customers-feedback-swiper-prev",
			},
		});
	}
	/* eslint-enable class-methods-use-this, no-unused-vars */
}

export default CustomersFeedback;
