/* eslint-disable prefer-destructuring, class-methods-use-this, no-undef, camelcase, prefer-template */
import ScrollMagic from "scrollmagic";
import Pagination from "./pagination";

class Filters {
	constructor() {
		this.filtersDropdown = document.getElementById("categories-filter");
		if (this.filtersDropdown) {
			this.init();
		}
	}

	init() {
		const filterPosts = this.filterPosts;
		const removeElementsByClass = this.removeElementsByClass;

		/* eslint-disable */
		this.filtersDropdown.addEventListener("change", function () {
			const postType = this.dataset.postType;
			const taxonomy = this.dataset.taxonomy;
			const columns = this.dataset.columns;
			const term = this.options[this.selectedIndex].dataset.term;
			const titleLength = this.dataset.titleLength;
			const pagination = this.dataset.pagination;
			const viewAll = this.dataset.viewAll;
			const maxPosts = this.dataset.maxPosts;

			filterPosts(
				postType,
				taxonomy,
				term,
				titleLength,
				pagination,
				viewAll,
				maxPosts,
				columns,
				this,
				removeElementsByClass
			);
		});
		/* eslint-enable */
	}

	/* eslint-disable */
	filterPosts(
		postType,
		taxonomy,
		term,
		titleLength,
		pagination,
		viewAll,
		maxPosts,
		columns,
		element,
		removeElementsByClass
	) {
		const request = window.ajaxReq();
		let url = encodeURI(scriptsAjaxVariables.ajax_url); // eslint-disable-line
		const data =
			"action=filter_posts" +
			"&post-type=" +
			postType +
			"&taxonomy=" +
			taxonomy +
			"&term=" +
			term +
			"&title-length=" +
			titleLength +
			"&pagination=" +
			pagination +
			"&view-all=" +
			viewAll +
			"&max-posts=" +
			maxPosts +
			"&columns=" +
			columns +
			"&nonce=" +
			scriptsAjaxVariables.nonce;
		/* eslint-enable */
		request.open("POST", url, true); // set true for async, false for sync request
		request.setRequestHeader(
			"Content-type",
			"application/x-www-form-urlencoded"
		);
		request.send(data); // or null, if no parameters are passed

		// On success append data
		request.onreadystatechange = () => {
			if (request.readyState === 4 && request.status === 200) {
				try {
					const grid = element.closest(".grid");
					const gridCards = grid.getElementsByClassName("js-grid-cards")[0];

					// Remove current data
					removeElementsByClass(gridCards, "grid-card");

					// Append data
					gridCards.innerHTML = request.responseText;

					// Reload load more button
					window.pagination = new Pagination();

					// Cards animation
					window.setAnimationScenes(new ScrollMagic.Controller());

					// Trigger lazy loading
					window.lazyLoading.init();
				} catch (error) {
					console.log(error); // eslint-disable-line no-console
				}
			}
		};
	}

	/* eslint-disable */
	removeElementsByClass(element, className) {
		const elements = element.getElementsByClassName(className);

		while (elements.length > 0) {
			elements[0].parentNode.removeChild(elements[0]);
		}
	}
	/* eslint-enable */
}

export default Filters;
