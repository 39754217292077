/* eslint-disable no-undef, class-methods-use-this, prefer-destructuring, no-param-reassign */

import intlTelInput from "intl-tel-input";

class Forms {
	constructor() {
		this.init();
	}

	init() {}

	newForm(form) {
		this.formPrivacyPolicy = document.getElementById("input-form");
		this.formSendButton = document.getElementsByClassName("contact-button")[0];
		this.closeNotificationButton =
			document.getElementById("close-notification");
		// Load Re-captcha V3 or throw an error
		if (googleRecaptchaWebKey) {
			// eslint-disable-line
			const script = document.createElement("script");
			script.setAttribute("type", "text/javascript");
			script.setAttribute(
				"src",
				`https://www.google.com/recaptcha/api.js?render=${googleRecaptchaWebKey}`
			); // eslint-disable-line
			document.getElementsByTagName("head")[0].appendChild(script);

			// Load international telephone input
			const input = document.querySelector("#phone");
			const countryData = window.intlTelInputGlobals.getCountryData();
			let currentLanguage = encodeURI(scriptsAjaxVariables.current_language); // eslint-disable-line

			if (countryData.length > 0) {
				for (let i = 0; i < countryData.length; i += 1) {
					const country = countryData[i];
					country.name = country.name.replace(/.+\((.+)\)/, "$1");
				}
			}

			if (currentLanguage === "en") {
				currentLanguage = "";
			}

			intlTelInput(input, {
				// Any initialisation options go here. Check https://github.com/jackocnr/intl-tel-input
				separateDialCode: "true",
				utilsScript: `https://${window.location.hostname}/wp-content/themes/adelsys/js/vendor/utils.js`,
				autoPlaceholder: "polite",
				initialCountry: `${currentLanguage}`,
				preferredCountries: [""],
			});

			// Make sure the privacy policy value is unchecked
			this.formPrivacyPolicy.checked = false;
			this.formPrivacyPolicy.value = "unchecked";

			// Listen for privacy policy checkbox changes
			this.formPrivacyPolicy.addEventListener(
				"change",
				this.privacyPolicyCheckboxToogle
			);

			// Prevent form submit
			form.addEventListener("submit", this.preventFormSubmit);

			// Form validations and submit
			this.formSendButton.addEventListener("click", this.submitValidateForm);

			// Close notificacion banner
			this.closeNotificationButton.addEventListener(
				"click",
				this.closeNotificationBanner
			);

			// Enable submit button
			this.formSendButton.disabled = false;
		} else {
			console.error("Set up the Google ReCaptcha V3 keys"); // eslint-disable-line no-console
		}
	}

	privacyPolicyCheckboxToogle(privacyPolicyCheckbox) {
		if (privacyPolicyCheckbox.srcElement.value === "unchecked") {
			privacyPolicyCheckbox.srcElement.value = "checked";
		} else {
			privacyPolicyCheckbox.srcElement.value = "unchecked";
		}
	}

	preventFormSubmit(evt) {
		evt.preventDefault();
	}

	submitValidateForm(evt) {
		// Get the form parameters
		const action = "contact_form";
		const firstName = this.form.querySelectorAll("[name=first_name]")[0];
		const email = this.form.querySelectorAll("[name=email]")[0];
		const phoneNumber = this.form.querySelectorAll("[name=phone_number]")[0];
		const city = this.form.querySelectorAll("[name=city]")[0];
		const companyName = this.form.querySelectorAll("[name=company_name]")[0];
		const file = this.form.querySelectorAll("[name=attachment_file]")[0];
		const message = this.form.querySelectorAll("[name=message]")[0];
		const messageIsRequired = this.form.querySelectorAll(
			"[name=user_message_required]"
		)[0];
		const privacyPolicy = this.form.querySelectorAll(
			"[name=privacy_policy]"
		)[0];
		const receiverEmail = this.form.querySelectorAll(
			"[name=receiver_email]"
		)[0];
		const successMessage = this.form.querySelectorAll(
			"[name=success_message]"
		)[0];
		const errorMessage = this.form.querySelectorAll("[name=error_message]")[0];
		const notificationsBanner = document.getElementById("notification-banner");
		const notificationMessage =
			document.getElementsByClassName("notification-text")[0];
		const currentLanguage = encodeURI(scriptsAjaxVariables.current_language); // eslint-disable-line
		const defaultLanguage = encodeURI(scriptsAjaxVariables.default_language); // eslint-disable-line
		const formData = new FormData();
		formData.append("action", action);

		if (firstName) {
			formData.append("first_name", firstName.value);
		}

		if (email) {
			formData.append("email", email.value);
		}

		if (phoneNumber) {
			formData.append("phone_number", phoneNumber.value);
		}

		if (city) {
			formData.append("city", city.value);
		}

		if (companyName) {
			formData.append("company_name", companyName.value);
		}

		if (message) {
			formData.append("message", message.value);
		}

		if (messageIsRequired) {
			formData.append("user_message_required", messageIsRequired.value);
		}

		if (file) {
			formData.append("attachment_file", file.files[0]);
		}

		if (privacyPolicy) {
			formData.append("privacy_policy", privacyPolicy.value);
		}

		if (currentLanguage) {
			formData.append("current_language", currentLanguage);
		}

		if (defaultLanguage) {
			formData.append("default_language", defaultLanguage);
		}

		if (receiverEmail) {
			formData.append("receiver_email", receiverEmail.value);
		}

		// Disable and animate the submit button
		evt.srcElement.disabled = true;
		evt.srcElement.classList.add("validating");

		// Recaptcha validation
		grecaptcha.ready(() => {
			// eslint-disable-line
			grecaptcha.execute(googleRecaptchaWebKey, { action }).then((token) => {
				// eslint-disable-line
				if (token) {
					formData.append("token", token);
				}

				// Ajax form submit
				const xmlhttp = window.ajaxReq();
				const url = encodeURI(scriptsAjaxVariables.ajax_url); // eslint-disable-line
				xmlhttp.open("POST", url, true); // set true for async, false for sync request
				xmlhttp.send(formData); // or null, if no parameters are passed

				// Validation
				xmlhttp.onreadystatechange = () => {
					if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
						try {
							const data = JSON.parse(xmlhttp.responseText);

							// Hide previous errors
							firstName.classList.remove("show-error");
							email.classList.remove("show-error");
							privacyPolicy.classList.remove("show-error");

							if (phoneNumber) {
								phoneNumber.classList.remove("show-error");
							}

							if (message) {
								message.classList.remove("show-error");
							}

							if (file) {
								file.classList.remove("show-error");
							}

							if (data.validation === false) {
								// Show errors
								if (data.first_name) {
									firstName.classList.add("show-error");
								}
								if (data.email) {
									email.classList.add("show-error");
								}
								if (data.phone_number) {
									phoneNumber.classList.add("show-error");
								}
								if (data.message) {
									message.classList.add("show-error");
								}
								if (data.file) {
									file.classList.add("show-error");
								}
								if (data.privacy_policy) {
									privacyPolicy.classList.add("show-error");
								}

								// Scroll to the first error.
								const elementsWithError =
									evt.srcElement.form.getElementsByClassName("show-error");
								elementsWithError[0].scrollIntoView({
									block: "center",
									behavior: "smooth",
								});
							} else if (data.success === false) {
								// Error notification message
								notificationMessage.innerHTML = errorMessage.value;

								// Show notification
								notificationsBanner.classList.add("active");
							} else {
								// Success notification message
								notificationMessage.innerHTML = successMessage.value;

								// Clear the form
								evt.srcElement.form.reset();

								// Show notification
								notificationsBanner.classList.add("active");
							}

							// Enable and stop the submit button animation
							evt.srcElement.disabled = false;
							evt.srcElement.classList.remove("validating");
						} catch (error) {
							// Show notification
							notificationsBanner.classList.add("active");
						}
					}
				};
			});
		});
	}

	closeNotificationBanner(evt) {
		evt.preventDefault();
		evt.srcElement.parentNode.classList.remove("active");
	}
}

export default Forms;
