/* eslint-disable no-use-before-define, no-undef, no-shadow, no-return-assign, no-param-reassign, array-callback-return, consistent-return */

import ready from "mf-js/modules/dom/ready";

const consentUI = (options) => {
	const { hashtag, getText } = Object.assign(options, {
		hashtag: "#cookies-consent",
	});

	const STATUS_WAIT = "wait";
	const STATUS_TRUE = "true";
	const STATUS_FALSE = "false";

	/** ************************************************
	 * MANAGER
	 ************************************************* */

	let manager = false;

	const setManager = (m) => {
		manager = m;
		return manager;
	};

	/** ************************************************
	 * BANNERS
	 ************************************************* */

	const banner = document.createElement("div");

	const closeBanner = () => {
		if (banner.parentNode) {
			banner.className = "cookies-banner";
		}
	};

	const createBanner = () => {
		banner.className = "cookies-banner";
		setTimeout(() => {
			banner.className = "cookies-banner cookies-banner--active";
		}, 500);
		// Banner Wrapper
		const bannerWrapper = document.createElement("div");
		bannerWrapper.className = "wrapper";
		banner.appendChild(bannerWrapper);

		// Banner Text
		const bannerText = document.createElement("div");
		bannerText.className = "cookies-banner__text";
		bannerWrapper.appendChild(bannerText);

		bannerText.innerHTML = getText("banner");

		// Banner Actions
		const bannerActions = document.createElement("div");
		bannerActions.className = "cookies-banner__actions";
		bannerWrapper.appendChild(bannerActions);

		const acceptAllButton = document.createElement("button");
		acceptAllButton.className = "accept";
		acceptAllButton.type = "button";
		acceptAllButton.tabIndex = "0";
		acceptAllButton.innerHTML = getText("accept");

		bannerActions.appendChild(acceptAllButton);

		acceptAllButton.addEventListener("click", () => {
			manager.acceptAll();
			closeBanner();
		});

		const detailsButton = document.createElement("button");
		detailsButton.className = "details";
		detailsButton.type = "button";
		detailsButton.tabIndex = "0";
		detailsButton.innerHTML = getText("customise");
		bannerActions.appendChild(detailsButton);

		detailsButton.addEventListener("click", () => {
			createDetails();
		});

		const rejectButton = document.createElement("button");
		rejectButton.className = "reject";
		rejectButton.type = "button";
		rejectButton.tabIndex = "0";
		rejectButton.innerHTML = getText("deny");
		bannerActions.appendChild(rejectButton);

		rejectButton.addEventListener("click", () => {
			manager.denyAll();
			closeBanner();
		});

		// Init
		ready(() => document.body.appendChild(banner));
	};

	const createModificationBanner = () => {
		const banner = document.createElement("div");
		banner.id = "cookiesalert";
		banner.style.display = "none";

		const closeBanner = () => {
			if (banner.parentNode) {
				banner.className = "cookies-banner";
			}
		};

		// Banner Content
		const bannerContent = document.createElement("div");
		bannerContent.className = "box";
		banner.appendChild(bannerContent);

		// Banner Actions
		const bannerActions = document.createElement("div");
		const modifyConfigButton = document.getElementById(
			"cookies-configuration-button"
		);
		if (modifyConfigButton) {
			modifyConfigButton.addEventListener("click", () => {
				createDetails();
				closeBanner();
			});
		}

		// Banner close
		const closeButton = document.createElement("div");
		closeButton.className = "cookies-banner__close";
		closeButton.role = "button";
		closeButton.tabIndex = "0";
		closeButton.ariaPressed = "false";
		closeButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" role="img" aria-labelledby="closeCookiesButtonTitle closeCookiesButtonDesc"><title>
			${cookiesCloseButtonText}
			</title><desc id="closeCookiesButtonDesc">Pulsa este botón para cerrar el banner de cookies</desc><path fill="#FFF" d="M28.941 31.786L.613 60.114a2.014 2.014 0 1 0 2.848 2.849l28.541-28.541 28.541 28.541c.394.394.909.59 1.424.59a2.014 2.014 0 0 0 1.424-3.439L35.064 31.786 63.41 3.438A2.014 2.014 0 1 0 60.562.589L32.003 29.15 3.441.59A2.015 2.015 0 0 0 .593 3.439l28.348 28.347z"></path></svg>`;
		bannerActions.appendChild(closeButton);

		closeButton.addEventListener("click", () => {
			closeBanner();
		});

		// Init
		ready(() => document.body.appendChild(banner));
	};

	/** ************************************************
	 * DETAILS
	 ************************************************* */

	// External button to open details externally. (Ex: Cookies policiy)
	const detailsExternalButtons = document.querySelectorAll(
		"a[href='#cookies-config']"
	);

	if (detailsExternalButtons.length > 0) {
		Array.from(detailsExternalButtons).forEach((detailsExternalButton) => {
			// Iterate over HTMLCollection https://stackoverflow.com/questions/3871547/js-iterating-over-result-of-getelementsbyclassname-using-array-foreach
			detailsExternalButton.addEventListener("click", () => {
				createDetails();
			});
		});
	}

	const createDetails = () => {
		const details = document.createElement("div");
		details.className = "cookies-banner__details";

		const closeDetails = () => {
			document.body.removeChild(details);
			window.removeEventListener("keydown", keyListener, false);

			if (hashtag !== "" && document.location.hash === hashtag) {
				document.location.hash = "";
			}
		};

		// Background
		const detailsBackground = document.createElement("div");
		detailsBackground.className = "cookies-banner__background";
		details.appendChild(detailsBackground);

		detailsBackground.addEventListener("click", () => {
			closeDetails();
		});

		// Modal
		const detailsModal = document.createElement("div");
		detailsModal.className = "cookies-banner__modal";
		details.appendChild(detailsModal);

		// Details Text
		const detailsText = document.createElement("div");
		detailsText.className = "modal__text";
		detailsModal.appendChild(detailsText);

		detailsText.innerHTML = getText("details");

		const createItem = (options) => {
			const { status, serviceName, label, accept, deny } = options;
			const item = document.createElement("div");
			item.className = "cookies-banner__item";
			item.setAttribute("data-status", status);

			// Label
			const itemLabel = document.createElement("div");
			itemLabel.className = "cookies-banner__item__label";
			itemLabel.innerHTML = label;
			item.appendChild(itemLabel);

			// Choices
			const itemChoices = document.createElement("div");
			itemChoices.className = "cookies-banner__item__choices";
			item.appendChild(itemChoices);

			const switchButton = document.createElement("label");
			switchButton.id = `${serviceName}__item__switch`;
			switchButton.className = "cookies-banner__item__switch";
			itemChoices.appendChild(switchButton);

			const switchCheckbox = document.createElement("input");
			switchCheckbox.id = `${serviceName}__item__checkbox`;
			switchCheckbox.type = "checkbox";
			switchCheckbox.checked = status === "true" ? "checked" : "";
			switchButton.appendChild(switchCheckbox);

			const switchSpan = document.createElement("span");
			switchSpan.className = "switch__slider";
			switchButton.appendChild(switchSpan);

			switchCheckbox.addEventListener("click", (e) => {
				const currentStatus = item.getAttribute("data-status");
				const switchCheckbox = document.getElementById(e.target.id);
				const detailsItems = document.getElementsByClassName(
					"cookies-banner__details--items"
				)[0];
				let singleServiceInputs = detailsItems.getElementsByTagName("input");
				let singleBannerItems = detailsItems.getElementsByClassName(
					"cookies-banner__item"
				);

				if (currentStatus === STATUS_TRUE) {
					item.setAttribute("data-status", STATUS_FALSE);
					switchCheckbox.checked = false;
					if (
						typeof singleServiceInputs === "object" &&
						typeof singleBannerItems === "object"
					) {
						singleBannerItems = [...singleBannerItems];
						singleServiceInputs = [...singleServiceInputs];
						if (serviceName === "all") {
							singleBannerItems.forEach((el) => {
								el.setAttribute("data-status", STATUS_FALSE);
							});
							singleServiceInputs.forEach((el) => (el.checked = false));
						} else {
							let modifyAllCheckbox = true;
							const checkElStatus = (el) => {
								if (el.getAttribute("data-status") === STATUS_TRUE) {
									modifyAllCheckbox = false;
								}
							};
							singleBannerItems.forEach((el) => checkElStatus(el));
							if (modifyAllCheckbox) {
								const allCheckBox = document.getElementById(
									"all__item__checkbox"
								);
								allCheckBox.checked = false;
							}
						}
					}
					deny();
					/* If it is a specific cookie, uncheck the all checkbox */
					if (serviceName !== "all") {
						const allCheckBox = document.getElementById("all__item__checkbox");
						allCheckBox.checked = false;
					}
				} else {
					item.setAttribute("data-status", STATUS_TRUE);
					switchCheckbox.checked = true;
					if (
						typeof singleServiceInputs === "object" &&
						typeof singleBannerItems === "object"
					) {
						singleBannerItems = [...singleBannerItems];
						singleServiceInputs = [...singleServiceInputs];
						if (serviceName === "all") {
							singleBannerItems.forEach((el) => {
								el.setAttribute("data-status", STATUS_TRUE);
							});
							singleServiceInputs.forEach((el) => (el.checked = true));
						} else {
							let modifyAllCheckbox = true;
							const checkElStatus = (el) => {
								if (el.getAttribute("data-status") === STATUS_FALSE) {
									modifyAllCheckbox = false;
								}
							};
							singleBannerItems.forEach((el) => checkElStatus(el));
							if (modifyAllCheckbox) {
								const allCheckBox = document.getElementById(
									"all__item__checkbox"
								);
								allCheckBox.checked = true;
							}
						}
					}
					accept();
				}
			});

			return item;
		};

		const services = manager.services();

		// Items All
		const detailsAll = document.createElement("div");
		detailsAll.className = "cookies-banner__details--all";
		detailsModal.appendChild(detailsAll);

		detailsAll.appendChild(
			createItem({
				status: services.reduce((status, item) => {
					switch (item.status) {
						case STATUS_TRUE:
							if (status === "" || status === STATUS_TRUE) {
								return STATUS_TRUE;
							}
							break;

						case STATUS_FALSE:
							if (status === "" || status === STATUS_FALSE) {
								return STATUS_FALSE;
							}
							break;

						default:
							return STATUS_WAIT;
					}
				}, ""),
				serviceName: "all",
				label: getText("allServices"),
				accept: () => {
					manager.acceptAll();
				},
				deny: () => {
					manager.denyAll();
				},
			})
		);

		// Items
		const detailsItems = document.createElement("div");
		detailsItems.className = "cookies-banner__details--items";
		detailsModal.appendChild(detailsItems);
		services.forEach((item) =>
			detailsItems.appendChild(
				createItem({
					status: item.status,
					serviceName: item.key,
					label:
						item.service[0].name +
						(item.service[0].uri
							? ` <a href="${item.service[0].uri}" target="_blank">${getText(
									"policyLinkLabel"
							  )}</a>`
							: ""),
					accept: () => manager.accept(item.key),
					deny: () => manager.deny(item.key),
				})
			)
		);

		// Details close
		const closeButton = document.createElement("div");
		closeButton.className = "cookies-banner__close cookies-banner__close--dark";
		closeButton.tabIndex = "0";
		closeButton.role = "button";
		closeButton.ariaPressed = "false";
		closeButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" role="img" aria-labelledby="closeCookiesButtonTitle closeCookiesButtonDesc"><title>
			${cookiesCloseButtonText}
			</title><desc id="closeCookiesButtonDesc">Pulsa este botón para cerrar el banner de cookies</desc><path fill="#FFF" d="M28.941 31.786L.613 60.114a2.014 2.014 0 1 0 2.848 2.849l28.541-28.541 28.541 28.541c.394.394.909.59 1.424.59a2.014 2.014 0 0 0 1.424-3.439L35.064 31.786 63.41 3.438A2.014 2.014 0 1 0 60.562.589L32.003 29.15 3.441.59A2.015 2.015 0 0 0 .593 3.439l28.348 28.347z"></path></svg>`;
		detailsModal.appendChild(closeButton);

		closeButton.addEventListener("click", () => {
			closeDetails();
		});

		const keyListener = (evt) => {
			if (evt.keyCode === 27) {
				closeDetails();
			}
		};

		// Save
		const itemSave = document.createElement("div");
		itemSave.className = "cookies-banner__save";
		detailsModal.appendChild(itemSave);

		const saveButton = document.createElement("button");
		saveButton.className = "save";
		saveButton.type = "button";
		saveButton.innerHTML = getText("save");
		itemSave.appendChild(saveButton);

		saveButton.addEventListener("click", () => {
			closeDetails();
			closeBanner();
			services.forEach((item) => {
				manager.save(item.key);
			});
		});

		window.addEventListener("keydown", keyListener, false);

		// Init
		ready(() => document.body.appendChild(details));
	};

	/** ************************************************
	 * EVENTS
	 ************************************************* */

	const requireConsent = () => createBanner({ manager });
	const modifyConsent = () => createModificationBanner(manager);

	if (hashtag !== "") {
		window.addEventListener(
			"hashchange",
			() => {
				if (document.location.hash === hashtag) {
					createDetails();
				}
			},
			false
		);

		if (document.location.hash === hashtag) {
			ready(() => createDetails());
		}
	}

	const o = {
		setManager,
		requireConsent,
		modifyConsent,
	};

	return o;
};

export default consentUI;
