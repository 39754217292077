import Swiper from "swiper/bundle";

class SliderGallery {
	constructor() {
		this.sliderGallerySections =
			document.getElementsByClassName("slider-gallery");
		if (this.sliderGallerySections[0]) {
			this.init();
		}
	}

	/* eslint-disable class-methods-use-this, no-unused-vars */
	init() {
		Array.from(this.sliderGallerySections).forEach((section) =>
			this.initSlider(section)
		);
	}

	initSlider(section) {
		const swiperContainer = section.querySelector(".swiper-container");
		const swiperButtonPrev = section.querySelector(".swiper-button-prev");
		const swiperButtonNext = section.querySelector(".swiper-button-next");
		this.swiperInstance = new Swiper(swiperContainer, {
			// Optional parameters
			direction: "horizontal",
			loop: false,
			slidesPerView: 1,
			spaceBetween: 30,

			// Responsive
			breakpoints: {
				992: {
					slidesPerView: 2.5,
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: swiperButtonNext,
				prevEl: swiperButtonPrev,
			},
		});
	}
	/* eslint-enable class-methods-use-this, no-unused-vars */
}

export default SliderGallery;
