import Swiper from "swiper/bundle";

class Clients {
	constructor() {
		this.clientsSections = document.getElementsByClassName("clients");
		if (this.clientsSections[0]) {
			this.init();
		}
	}

	/* eslint-disable class-methods-use-this, no-unused-vars */
	init() {
		Array.from(this.clientsSections).forEach((section) =>
			this.initSlider(section)
		);
	}

	initSlider(section) {
		const sliderContainer = section.querySelector(".swiper-container");
		const slider = new Swiper(sliderContainer, {
			// Optional parameters
			loop: true,
			autoplay: {
				delay: 1,
				disableOnInteraction: false,
			},
			slidesPerView: "auto",
			spaceBetween: 0,
			speed: 5000,
			grabCursor: false,
			mousewheelControl: false,
			keyboardControl: false,

			// Navigation arrows
			navigation: {
				nextEl: ".js-swiper-next",
				prevEl: ".js-swiper-prev",
			},
		});
	}
	/* eslint-enable class-methods-use-this, no-unused-vars */
}

export default Clients;
