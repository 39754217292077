/* eslint-disable class-methods-use-this, no-use-before-define, no-param-reassign, prefer-destructuring */
class Facts {
	constructor() {
		this.factsSections = document.getElementsByClassName("facts");
		if (this.factsSections[0]) {
			this.init();
		}
	}

	init() {
		function resetNumbers() {
			const factsSections = document.getElementsByClassName("facts");
			Array.prototype.forEach.call(factsSections, (factsSection) => {
				const factsListItems =
					factsSection.getElementsByClassName("js-facts-list-item");
				for (let i = 0; i < factsListItems.length; i += 1) {
					const factsListItemNumber = factsListItems
						.item(i)
						.getElementsByClassName("js-facts-number")[0];
					factsListItemNumber.getElementsByTagName("span")[0].innerHTML = 0;
				}
			});
		}

		function startNumbersAnimation() {
			const factsSections = document.getElementsByClassName("facts");

			function getRandomArbitrary(min, max) {
				return Math.random() * (max - min) + min;
			}

			function numbersAnimation(elem, endNumber) {
				let number = 0;
				const randomNumber = getRandomArbitrary(1, 10);
				let acceleration = endNumber / 20 + randomNumber;

				if (acceleration < 1) {
					acceleration = 1;
				}

				function frame() {
					if (number + acceleration > endNumber) {
						number = endNumber;
					} else {
						number += acceleration;
					}
					/* show frame */
					elem.innerHTML =
						Math.floor(number); /* eslint-disable no-param-reassign */
					if (number >= endNumber) {
						clearInterval(id);
					} // check finish condition
				}
				const id = setInterval(frame, 70); // draw every 1ms
			}

			Array.prototype.forEach.call(factsSections, (factsSection) => {
				if (factsSection.classList.contains("animated")) {
					const factsListItems =
						factsSection.getElementsByClassName("js-facts-list-item");
					for (let i = 0; i < factsListItems.length; i += 1) {
						const factsListItemNumber = factsListItems
							.item(i)
							.getElementsByClassName("js-facts-number")[0];
						const dataCount = factsListItemNumber.dataset.count;
						const dataAnimation = factsListItemNumber.dataset.animation;
						if (dataAnimation === "false") {
							numbersAnimation(
								factsListItemNumber.getElementsByTagName("span")[0],
								dataCount
							);
							factsListItemNumber.dataset.animation = "true";
						}
					}
				}
			});
		}

		resetNumbers();
		window.addEventListener("scroll", startNumbersAnimation);
	}
}

export default Facts;
