import Swiper, { Navigation } from "swiper";

class Products {
	constructor() {
		this.cardsSliderSections = document.getElementsByClassName("products");
		if (this.cardsSliderSections[0]) {
			Swiper.use([Navigation]);
			this.init();
		}
	}

	/* eslint-disable class-methods-use-this, no-unused-vars */
	init() {
		Array.from(this.cardsSliderSections).forEach((section) => {
			this.initSlider(section);
			this.initPopups(section);
		});
	}

	initSlider(section) {
		this.swiperContainer = section.querySelector(".swiper-container");
		this.swiperButtonPrev = section.querySelector(".swiper-button-prev");
		this.swiperButtonNext = section.querySelector(".swiper-button-next");
		this.swiperInstance = new Swiper(this.swiperContainer, {
			freeMode: true,
			grabCursor: true,
			slidesPerView: 1.4,
			slidesOffsetAfter: 300,
			spaceBetween: 16,

			// Responsive
			breakpoints: {
				992: {
					slidesPerView: 3.3,
					slidesOffsetAfter: 0,
					spaceBetween: 56,
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: this.swiperButtonNext,
				prevEl: this.swiperButtonPrev,
			},
		});
	}

	initPopups(section) {
		const header = document.querySelector(".header");
		const productSlides = section.querySelectorAll(".swiper-slide");
		const swiperWrapper = section.querySelector(".swiper-wrapper");

		function blockScroll() {
			if (document.body.classList.contains("u-mobile-block-scroll")) {
				document.body.classList.remove("u-mobile-block-scroll");
			} else {
				document.body.classList.add("u-mobile-block-scroll");
			}
		}

		function blockSlider() {
			if (swiperWrapper.classList.contains("u-block-slider")) {
				swiperWrapper.classList.remove("u-block-slider");
			} else {
				swiperWrapper.classList.add("u-block-slider");
			}
		}

		Array.from(productSlides).forEach((productSlide) => {
			const openButton = productSlide.querySelector(".product-card__open");
			const closeButton = productSlide.querySelector(".product-card__close");
			const popup = productSlide.querySelector(".product-card__popup");

			// Open popup
			if (openButton) {
				openButton.addEventListener("click", () => {
					// Block scroll
					blockScroll();

					// Block slider
					blockSlider(swiperWrapper);

					// Hide header
					header.classList.remove("scrolled", "scrolled--show");

					if (popup.classList.contains("product-card__popup--active")) {
						popup.classList.remove("product-card__popup--active");
					} else {
						popup.classList.add("product-card__popup--active");
					}
				});
			}

			// Close popup
			if (closeButton) {
				closeButton.addEventListener("click", () => {
					// Unlock scroll
					blockScroll();

					// Unlock slider
					blockSlider(swiperWrapper);

					if (popup.classList.contains("product-card__popup--active")) {
						popup.classList.remove("product-card__popup--active");
					} else {
						popup.classList.add("product-card__popup--active");
					}
				});
			}
		});
	}
	/* eslint-enable class-methods-use-this, no-unused-vars */
}

export default Products;
