function goToTop() {
	document.body.scrollTop = 0; // For Safari
	document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

class Footer {
	constructor() {
		const goToTopButton =
			document.getElementsByClassName("js-go-back-to-top")[0];

		if (goToTopButton) {
			goToTopButton.addEventListener("click", goToTop);
		}
	}
}

export default Footer;
