import Swiper, { Navigation } from "swiper";

class SliderCards {
	constructor() {
		this.cardsSliderSections = document.getElementsByClassName("cards-slider");
		if (this.cardsSliderSections[0]) {
			Swiper.use([Navigation]);
			this.init();
		}
	}

	/* eslint-disable class-methods-use-this, no-unused-vars */
	init() {
		Array.from(this.cardsSliderSections).forEach((section) =>
			this.initSlider(section)
		);
	}

	initSlider(section) {
		this.swiperContainer = section.querySelector(".swiper-container");
		this.swiperButtonPrev = section.querySelector(".swiper-button-prev");
		this.swiperButtonNext = section.querySelector(".swiper-button-next");
		this.swiperInstance = new Swiper(this.swiperContainer, {
			freeMode: true,
			grabCursor: true,
			slidesPerView: 1.35,
			slidesOffsetAfter: 300,
			spaceBetween: 56,

			// Responsive
			breakpoints: {
				992: {
					slidesPerView: 3.3,
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: this.swiperButtonNext,
				prevEl: this.swiperButtonPrev,
			},
		});
	}
	/* eslint-enable class-methods-use-this, no-unused-vars */
}

export default SliderCards;
